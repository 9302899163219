import React from 'react';
import PropTypes from 'prop-types';

const HomePage = ({isLoggedIn}) => {
  return (
    <div className="p-8">
      <table className="table-auto border">
        <thead>
          <tr>
            <th className="font-bold p-2 border-b text-left bg-gray-800 text-white">Name</th>
            <th className="font-bold p-2 border-b text-left bg-gray-800 text-white">Value</th>
          </tr>
        </thead>
        <tbody>
          <tr className="odd:bg-gray-300">
            <td className="font-bold p-2 border-b border-l text-left ">Api Url</td>
            <td className="p-2 border-b border-l text-left">{process.env.REACT_APP_API_URL}</td>
          </tr>
          <tr className="odd:bg-gray-300">
            <td className="font-bold p-2 border-b border-l text-left">Client Id</td>
            <td className="p-2 border-b border-l text-left">{process.env.REACT_APP_IDENTITY_CLIENT_ID}</td>
          </tr>
          <tr className="odd:bg-gray-300">
            <td className="font-bold p-2 border-b border-l text-left">Issuer</td>
            <td className="p-2 border-b border-l text-left">{process.env.REACT_APP_IDENTITY_AUTHORITY}</td>
          </tr>
          <tr className="odd:bg-gray-300">
            <td className="font-bold p-2 border-b border-l text-left">Scope</td>
            <td className="p-2 border-b border-l text-left">{process.env.REACT_APP_IDENTITY_SCOPE}</td>
          </tr>
          <tr className="odd:bg-gray-300">
            <td className="font-bold p-2 border-b border-l text-left">Redirect Url</td>
            <td className="p-2 border-b border-l text-left">{process.env.REACT_APP_IDENTITY_REDIRECT_URL}</td>
          </tr>
          <tr className="odd:bg-gray-300">
            <td className="font-bold p-2 border-b border-l text-left">LoggedIn</td>
            {isLoggedIn ? (
              <td className="p-2 border-b border-l text-left">Yes</td>
            ) : (
              <td className="p-2 border-b border-l text-left">No</td>
            )}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

HomePage.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};

export default HomePage;
