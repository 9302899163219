import React from 'react';
import authService from '../services/AuthService.js';

const Logout = () => {
  const handleLogout = () => {
    return authService.logout();
  };

  return (
    <div>
      <h1 className="font-bold text-xl mb-4">Logout</h1>
      <p>Click the button to login.</p>
      <div>
        <button
          onClick={handleLogout}
          type="button"
          className="bg-blue-500 hover:bg-red-700 text-white font-bold py-2
                      px-4 rounded"
        >
          Logout
        </button>
      </div>
    </div>
  );
}

export default Logout;
