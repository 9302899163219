// import process from 'node:process';
import {UserManager} from 'oidc-client-ts';

const userManager = new UserManager({
  authority: process.env.REACT_APP_IDENTITY_AUTHORITY,
  client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_IDENTITY_REDIRECT_URL,
  response_type: "code",
  scope: process.env.REACT_APP_IDENTITY_SCOPE,
  revokeTokenTypes: ["refresh_token"],
  automaticSilentRenew: false
  // metadataUrl: process.env.REACT_APP_IDENTITY_METADATA_URL,
});

export default userManager;
