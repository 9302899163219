import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {filesize} from 'filesize';
import moment from 'moment';
import {Link, useParams} from 'react-router-dom';

const ReleasePage = ({accessToken}) => {
  const endpoint = '/releases';
  const [release, setRelease] = useState([]);
  const parameters = useParams();

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}${endpoint}/${parameters.releaseId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
          },
        },
      )
      .then((release) => {
        console.log(release.data);
        console.log('Release fetched successfully');
        setRelease(release.data);
      })
      .catch((error) => console.error('Fetching release failed:', error));
  }, [accessToken, parameters.releaseId]);

  return (
    <div className="w-full">
      <h1 className="font-bold text-3xl mb-4">Release</h1>
      <table className="table-auto border">
        <thead>
          <tr>
            <th className="font-bold p-2 border-b text-left bg-gray-800 text-white">
              Name
            </th>
            <th className="font-bold p-2 border-b text-left bg-gray-800 text-white">
              Value
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="odd:bg-gray-300">
            <td className="font-bold p-2 border-b border-l text-left ">Id</td>
            <td className="p-2 border-b border-l text-left">{release.Id}</td>
          </tr>
          <tr className="odd:bg-gray-300">
            <td className="font-bold p-2 border-b border-l text-left ">
              Description
            </td>
            <td className="p-2 border-b border-l text-left">
              {release.Description}
            </td>
          </tr>
          <tr className="odd:bg-gray-300">
            <td className="font-bold p-2 border-b border-l text-left ">
              CreatedAt
            </td>
            <td className="p-2 border-b border-l text-left">
              {moment(release.CreatedAt, moment.ISO_8601).fromNow()}
            </td>
          </tr>
          <tr className="odd:bg-gray-300">
            <td className="font-bold p-2 border-b border-l text-left ">
              EnabledAt
            </td>
            <td className="p-2 border-b border-l text-left">
              {release.EnabledAt
                ? moment(release.EnabledAt, moment.ISO_8601).fromNow()
                : 'Disabled'}
            </td>
          </tr>
          <tr className="odd:bg-gray-300">
            <td className="font-bold p-2 border-b border-l text-left ">
              DeletedAt
            </td>
            <td className="p-2 border-b border-l text-left">
              {release.EnabledAt
                ? moment(release.DeletedAt, moment.ISO_8601).fromNow()
                : 'Not Deleted'}
            </td>
          </tr>
          <tr className="odd:bg-gray-300">
            <td className="font-bold p-2 border-b border-l text-left ">
              ChunkCount
            </td>
            <td className="p-2 border-b border-l text-left">
              {release.ChunkCount}
            </td>
          </tr>
          <tr className="odd:bg-gray-300">
            <td className="font-bold p-2 border-b border-l text-left ">
              FileSize
            </td>
            <td className="p-2 border-b border-l text-left">
              {release.FileSize
                ? filesize(release.FileSize, {standard: 'si'})
                : '0'}
            </td>
          </tr>

          <tr className="odd:bg-gray-300">
            <td className="font-bold p-2 border-b border-l text-left ">
              Digest Type
            </td>
            <td className="p-2 border-b border-l text-left">
              {release.DigestType}
            </td>
          </tr>
          <tr className="odd:bg-gray-300">
            <td className="font-bold p-2 border-b border-l text-left ">
              Signature Type
            </td>
            <td className="p-2 border-b border-l text-left">
              {release.SignatureType}
            </td>
          </tr>
          <tr className="odd:bg-gray-300">
            <td className="font-bold p-2 border-b border-l text-left ">
              Manifest URL
            </td>
            <td className="p-2 border-b border-l text-left">
              <a href={release.ManifestUrl}>{release.ManifestUrl}</a>
            </td>
          </tr>
          <tr className="odd:bg-gray-300">
            <td className="font-bold p-2 border-b border-l text-left ">Kid</td>
            <td className="p-2 border-b border-l text-left">{release.Kid}</td>
          </tr>
          <tr className="odd:bg-gray-300">
            <td className="font-bold p-2 border-b border-l text-left ">
              Signature
            </td>
            <td className="p-2 border-b border-l text-left">
              {release.Signature}
            </td>
          </tr>
        </tbody>
      </table>
      <Link to="/releases">Back to Releases</Link>
    </div>
  );
};

ReleasePage.propTypes = {
  accessToken: PropTypes.string.isRequired,
};

export default ReleasePage;
