import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';
import {Link, useNavigate} from 'react-router-dom';

const DelegationsPage = ({accessToken}) => {
  const endpoint = '/delegations';
  const [delegations, setDelegations] = useState([]);
  const navigate = useNavigate();

  // 9971730d-438f-4db7-9bf8-089c42031661
  // 99a4fca7-bb46-4826-ac97-1097d3f66d3e
  //
  // 6d4bbd44-1549-437c-8fb9-90a848c1ee3d
  // 85b8d5db-fbdb-4c0d-8b09-70096a399626
  // 828c10b3-2f80-4ee9-aa34-885937ddf97b
  // c8f2f6ce-272f-4e4b-8167-fb6eead2f4ed

  const deleteDelegation = (accessToken, id) => {
    if (accessToken) {
      axios
        .delete(`${process.env.REACT_APP_API_URL}${endpoint}/${id}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
          },
        })
        .then(() => {
          console.log('Delegation deleted successfully');
          getDelegations(accessToken)
            .then((delegations) => {
              setDelegations(delegations.data);
            })
            .catch((error) => {
              console.error('Fetching releases failed:', error);
            });
        })
        .catch((error) => console.error('Enabling release failed:', error));
    }
  };

  const getDelegations = (accessToken) => {
    return axios.get(`${process.env.REACT_APP_API_URL}${endpoint}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    });
  };

  useEffect(() => {
    getDelegations(accessToken)
      .then((releases) => {
        setDelegations(releases.data);
      })
      .catch((error) => console.error('Fetching releases failed:', error));
  }, [accessToken]);

  return (
    <div className="w-full">
      <h1 className="font-bold text-3xl mb-4">Delegations</h1>
      <div className="w-full text-right">
        <Link
          to="/delegations/new"
          type="button"
          className="bg-blue-700 hover:bg-blue-500 text-white font-bold px-2 py-2 my-4 rounded inline-block"
        >
          New Delegation
        </Link>
      </div>

      <table className="table-auto border w-full clear-both">
        <thead>
          <tr>
            <th className="font-bold p-2 border-b text-left bg-gray-800 text-white">
              Pool Name
            </th>
            <th className="font-bold p-2 border-b text-left bg-gray-800 text-white">
              Node Name
            </th>
            <th className="font-bold p-2 border-b text-left bg-gray-800 text-white">
              Created At
            </th>
            <th className="font-bold p-2 border-b text-left bg-gray-800 text-white">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {delegations.map((delegation) => (
            <tr key={delegation.Id} className="odd:bg-gray-300">
              <td className="p-2 border-b border-l text-left">
                {delegation.PoolName}
              </td>
              <td className="p-2 border-b border-l text-left">
                {delegation.NodeName}
              </td>
              <td className="p-2 border-b border-l text-right">
                {moment(delegation.CreatedAt, moment.ISO_8601).fromNow()}
              </td>
              <td>
                <button
                  type="button"
                  className="bg-blue-700 hover:bg-blue-500 text-white font-bold mr-4 py-2 px-4 rounded"
                  onClick={() =>
                    navigate(`/delegations/${delegation.Id}`, {replace: true})
                  }
                >
                  View
                </button>
                <button
                  type="button"
                  className="bg-blue-700 hover:bg-blue-500 text-white font-bold mr-4 py-2 px-4 rounded"
                  onClick={() => deleteDelegation(accessToken, delegation.Id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

DelegationsPage.propTypes = {
  accessToken: PropTypes.string.isRequired,
};

export default DelegationsPage;
