import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import authService from './services/AuthService.js';
import AuthCallback from './components/AuthCallback.js';
import HomePage from './components/HomePage.js';
import ImportReleasePage from './components/ImportReleasePage.js';
import Login from './components/Login.js';
import Logout from './components/Logout.js';
import Navigation from './components/Navigation.js';
import NewNodePage from './components/new-node-page.js';
import NodePage from './components/node-page.js';
import NodesPage from './components/nodes-page.js';
import NewPoolPage from './components/new-pool-page.js';
import PoolPage from './components/pool-page.js';
import PoolsPage from './components/pools-page.js';
import NewDelegationPage from './components/new-delegation-page.js';
import DelegationPage from './components/delegation-page.js';
import DelegationsPage from './components/delegations-page.js';
import ReleasePage from './components/ReleasePage.js';
import ReleasesPage from './components/ReleasesPage.js';
import ShowProfile from './components/ShowProfile.js';

function App() {
  const [user, setUser] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    authService
      .getUser()
      .then((user) => {
        if (user === null) {
          setIsLoggedIn(false);
          setUser(null);
        } else {
          setIsLoggedIn(true);
          setUser(user);
        }
      })
      .catch((error) => console.error('Fetching user profile failed:', error));
  }, [user]);

  return (
    <Router basename="/">
      <div className="min-h-screen flex">
        <Navigation isLoggedIn={isLoggedIn} />

        <div className="w-3/4 bg-gray-100 p-4">
          <Routes>
            <Route path="/callback" element={<AuthCallback />} />
            <Route
              path="/releases"
              element={
                isLoggedIn ? (
                  <ReleasesPage accessToken={user.access_token} />
                ) : (
                  <Login />
                )
              }
            />
            <Route
              path="/releases/new"
              element={
                isLoggedIn ? (
                  <ImportReleasePage accessToken={user.access_token} />
                ) : (
                  <Login />
                )
              }
            />
            <Route
              path="/releases/:releaseId"
              element={
                isLoggedIn ? (
                  <ReleasePage accessToken={user.access_token} />
                ) : (
                  <Login />
                )
              }
            />
            <Route path="/" element={<HomePage isLoggedIn={isLoggedIn} />} />
            <Route
              path="/profile"
              element={
                isLoggedIn ? (
                  <ShowProfile
                    profile={user.profile}
                    accessToken={user.access_token}
                  />
                ) : (
                  <Login />
                )
              }
            />
            <Route
              path="/nodes"
              element={
                isLoggedIn ? (
                  <NodesPage accessToken={user.access_token} />
                ) : (
                  <Login />
                )
              }
            />
            <Route
              path="/nodes/new"
              element={
                isLoggedIn ? (
                  <NewNodePage accessToken={user.access_token} />
                ) : (
                  <Login />
                )
              }
            />
            <Route
              path="/nodes/:nodeId"
              element={
                isLoggedIn ? (
                  <NodePage accessToken={user.access_token} />
                ) : (
                  <Login />
                )
              }
            />
            <Route
              path="/pools"
              element={
                isLoggedIn ? (
                  <PoolsPage accessToken={user.access_token} />
                ) : (
                  <Login />
                )
              }
            />
            <Route
              path="/pools/new"
              element={
                isLoggedIn ? (
                  <NewPoolPage accessToken={user.access_token} />
                ) : (
                  <Login />
                )
              }
            />
            <Route
              path="/pools/:poolId"
              element={
                isLoggedIn ? (
                  <PoolPage accessToken={user.access_token} />
                ) : (
                  <Login />
                )
              }
            />
            <Route
              path="/delegations"
              element={
                isLoggedIn ? (
                  <DelegationsPage accessToken={user.access_token} />
                ) : (
                  <Login />
                )
              }
            />
            <Route
              path="/delegations/new"
              element={
                isLoggedIn ? (
                  <NewDelegationPage accessToken={user.access_token} />
                ) : (
                  <Login />
                )
              }
            />
            <Route
              path="/delegations/:delegationId"
              element={
                isLoggedIn ? (
                  <DelegationPage accessToken={user.access_token} />
                ) : (
                  <Login />
                )
              }
            />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
