import React from 'react';
import PropTypes from 'prop-types';

const ShowProfile = ({profile, accessToken}) => {
  return (
    <div className="p-8">
      <table className="table-auto border">
        <thead>
          <tr>
            <th className="font-bold p-2 border-b text-left bg-gray-800 text-white">
              Name
            </th>
            <th className="font-bold p-2 border-b text-left bg-gray-800 text-white">
              Value
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="odd:bg-gray-300">
            <td className="font-bold p-2 border-b border-l text-left ">
              Username
            </td>
            <td className="p-2 border-b border-l text-left">
              {profile['cognito:username']}
            </td>
          </tr>
          <tr className="odd:bg-gray-300">
            <td className="font-bold p-2 border-b border-l text-left ">
              Access Token
            </td>
            <td className="p-2 border-b border-l text-left">
              <textarea rows="4" cols="50">
                {accessToken}
              </textarea>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

ShowProfile.propTypes = {
  profile: PropTypes.object.isRequired,
  accessToken: PropTypes.string.isRequired,
};

export default ShowProfile;
