import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';
import {Link, useParams} from 'react-router-dom';

const PoolPage = ({accessToken}) => {
  const endpoint = '/pools';
  const [pool, setPool] = useState([]);
  const parameters = useParams();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}${endpoint}/${parameters.poolId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
        },
      })
      .then((pool) => {
        console.log(pool.data);
        console.log('Pool fetched successfully');
        setPool(pool.data);
      })
      .catch((error) => console.error('Fetching pool failed:', error));
  }, [accessToken, parameters.poolId]);

  return (
    <div className="w-full">
      <h1 className="font-bold text-3xl mb-4">Pool</h1>
      <table className="table-auto border">
        <thead>
          <tr>
            <th className="font-bold p-2 border-b text-left bg-gray-800 text-white">
              Name
            </th>
            <th className="font-bold p-2 border-b text-left bg-gray-800 text-white">
              Value
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="odd:bg-gray-300">
            <td className="font-bold p-2 border-b border-l text-left ">Id</td>
            <td className="p-2 border-b border-l text-left">{pool.Id}</td>
          </tr>
          <tr className="odd:bg-gray-300">
            <td className="font-bold p-2 border-b border-l text-left ">Name</td>
            <td className="p-2 border-b border-l text-left">{pool.Name}</td>
          </tr>
          <tr className="odd:bg-gray-300">
            <td className="font-bold p-2 border-b border-l text-left ">
              Description
            </td>
            <td className="p-2 border-b border-l text-left">
              {pool.Description}
            </td>
          </tr>
          <tr className="odd:bg-gray-300">
            <td className="font-bold p-2 border-b border-l text-left ">
              CreatedAt
            </td>
            <td className="p-2 border-b border-l text-left">
              {moment(pool.CreatedAt, moment.ISO_8601).fromNow()}
            </td>
          </tr>
          <tr className="odd:bg-gray-300">
            <td className="font-bold p-2 border-b border-l text-left ">
              Access Key
            </td>
            <td className="p-2 border-b border-l text-left">
              {pool.AccessKey}
            </td>
          </tr>
        </tbody>
      </table>
      <Link to="/pools">Back to Pools</Link>
    </div>
  );
};

PoolPage.propTypes = {
  accessToken: PropTypes.string.isRequired,
};

export default PoolPage;
