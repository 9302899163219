import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';

const NewDelegationPage = ({accessToken}) => {
  const endpoint = '/delegations';
  const nodeIdReference = useRef(null);
  const poolIdReference = useRef(null);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    if (accessToken) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}${endpoint}`,
          {
            PoolId: poolIdReference.current.value,
            NodeId: nodeIdReference.current.value,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              Accept: 'application/json',
              'Content-Type': 'application/json;charset=UTF-8',
            },
          },
        )
        .then((response) => {
          console.log('Node created successfully');
          console.log(response.data);
          navigate(`/delegations/${response.data.Id}`, {replace: true});
        })
        .catch((error) => console.error('Creating delegation failed:', error));
    }
  };

  return (
    <div className="w-full">
      <h1 className="font-bold text-3xl mb-4">New Delegation</h1>
      <form className="w-full" onSubmit={handleSubmit}>
        <div className="mb-6">
          <label
            htmlFor="name"
            className="block mb-2 text-xl font-medium text-gray-900"
          >
            Pool Id
          </label>
          <input
            ref={poolIdReference}
            type="text"
            id="newPoolId"
            name="PoolId"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>
        <div className="mb-6">
          <label
            htmlFor="url"
            className="block mb-2 text-xl font-medium text-gray-900"
          >
            Node Id
          </label>
          <input
            ref={nodeIdReference}
            type="text"
            id="newNodeId"
            name="NodeId"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          />
        </div>
        <button
          type="submit"
          className="text-white bg-blue-500 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 text-lg font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
        >
          Create Node
        </button>
      </form>
    </div>
  );
};

NewDelegationPage.propTypes = {
  accessToken: PropTypes.string.isRequired,
};

export default NewDelegationPage;
