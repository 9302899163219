import React, {useEffect} from 'react';
import authService from '../services/AuthService.js';

const AuthCallback = () => {
  useEffect(() => {
    authService
      .handleCallback()
      .then(() => {
        console.log('Callback called');
        window.location = '/';
      })
      .catch((error) => {
        console.log('Callback called');
        console.error(error);
      });
  }, []);

  return <div>Loading...</div>;
};

export default AuthCallback;
