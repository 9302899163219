import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {filesize} from 'filesize';
import moment from 'moment';
import {Link} from 'react-router-dom';

const ReleasesPage = ({accessToken}) => {
  const endpoint = '/releases';
  const [releases, setReleases] = useState([]);

  const getReleases = (accessToken) => {
    return axios.get(`${process.env.REACT_APP_API_URL}${endpoint}`, {
      headers: {
        Accept: 'application/json',
        Cookie: `access_token:${accessToken}`,
        // 'Content-Type': 'application/json;charset=UTF-8',
      },
    });
  };

  useEffect(() => {
    getReleases(accessToken)
      .then((releases) => {
        setReleases(releases.data);
      })
      .catch((error) => console.error('Fetching releases failed:', error));
  }, [accessToken]);

  const enableRelease = (id) => {
    if (accessToken) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}${endpoint}/${id}/enable`,
          {},
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              Accept: 'application/json',
              'Content-Type': 'application/json;charset=UTF-8',
            },
          },
        )
        .then(() => {
          console.log('Release enabled successfully');
          getReleases(accessToken)
            .then((releases) => {
              setReleases(releases.data);
            })
            .catch((error) => {
              console.error('Fetching releases failed:', error);
            });
        })
        .catch((error) => console.error('Enabling release failed:', error));
    }
  };

  const disableRelease = (id) => {
    if (accessToken) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}${endpoint}/${id}/disable`,
          {},
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              Accept: 'application/json',
              'Content-Type': 'application/json;charset=UTF-8',
            },
          },
        )
        .then(() => {
          console.log('Release disabled successfully');
          getReleases(accessToken)
            .then((releases) => {
              setReleases(releases.data);
            })
            .catch((error) => {
              console.error('Fetching releases failed:', error);
            });
        })
        .catch((error) => console.error('Enabling release failed:', error));
    }
  };

  const deleteRelease = (id) => {
    if (accessToken) {
      axios
        .post(`https://api.example.com/releases/${id}/enable`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
          },
        })
        .then(() => {
          console.log('Release enabled successfully');
        })
        .catch((error) => console.error('Enabling release failed:', error));
    }
  };

  return (
    <div className="w-full">
      <h1 className="font-bold text-3xl mb-4">Release Management</h1>
      <div className="w-full text-right">
        <Link
          to="/releases/new"
          type="button"
          className="bg-blue-700 hover:bg-blue-500 text-white font-bold px-2 py-2 my-4 rounded inline-block"
        >
          New Release
        </Link>
      </div>

      <table className="table-auto border w-full clear-both">
        <thead>
          <tr>
            <th className="font-bold p-2 border-b text-left bg-gray-800 text-white">
              Release Id
            </th>
            <th className="font-bold p-2 border-b text-left bg-gray-800 text-white">
              Description
            </th>
            <th className="font-bold p-2 border-b text-left bg-gray-800 text-white">
              File Size
            </th>
            <th className="font-bold p-2 border-b text-left bg-gray-800 text-white">
              Imported
            </th>
            <th className="font-bold p-2 border-b text-left bg-gray-800 text-white">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {releases.map((release) => (
            <tr key={release.Id} className="odd:bg-gray-300">
              <td className="font-bold p-2 border-b border-l text-left ">
                <Link to={`/releases/${release.Id}`}>{release.Id}</Link>
              </td>
              <td className="p-2 border-b border-l text-left">
                {release.Description}
              </td>
              <td className="p-2 border-b border-l text-right">
                {filesize(release.FileSize, {standard: 'si'})}
              </td>
              <td className="p-2 border-b border-l text-right">
                {moment(release.CreatedAt, moment.ISO_8601).fromNow()}
              </td>
              <td className="p-2 border-b border-l text-left">
                {release.EnabledAt === null ? (
                  <div>
                    <button
                      type="button"
                      className="bg-green-500 hover:bg-blue-700 text-white font-bold mr-4 py-2 px-4 rounded"
                      onClick={() => enableRelease(release.Id)}
                    >
                      Enable
                    </button>
                    <button
                      type="button"
                      className="bg-red-500 hover:bg-blue-700 text-white font-bold mr-4 py-2 px-4 rounded"
                      onClick={() => deleteRelease(release.Id)}
                    >
                      Delete
                    </button>
                  </div>
                ) : (
                  <button
                    type="button"
                    className="bg-orange-500 hover:bg-blue-700 text-white font-bold mr-4 py-2 px-4 rounded"
                    onClick={() => disableRelease(release.Id)}
                  >
                    Disable
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

ReleasesPage.propTypes = {
  accessToken: PropTypes.string.isRequired,
};

export default ReleasesPage;
