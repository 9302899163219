import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';
import {Link} from 'react-router-dom';

const NodesPage = ({accessToken}) => {
  const endpoint = '/nodes/list';
  const [results, setResults] = useState([]);
  const [page, setPage] = useState({});

  const getNodes = (accessToken, page) => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}${endpoint}?page=${page}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
        },
      })
      .then((result) => {
        setResults(result.data.data);
        setPage(result.data.page);
      });
  };

  useEffect(() => {
    getNodes(accessToken, 1)
      .then(() => {
        console.log('Nodes fetched successfully');
      })
      .catch((error) => console.error('Fetching releases failed:', error));
  }, [accessToken]);

  const goToPage = (page) => {
    getNodes(accessToken, page).then(() => {
      console.log('Nodes fetched successfully', page);
    });
  };

  return (
    <div className="w-full">
      <h1 className="font-bold text-3xl mb-4">Nodes</h1>
      <table className="table-auto border w-full clear-both">
        <thead>
          <tr>
            <th className="font-bold p-2 border-b text-left bg-gray-800 text-white">
              Node Id
            </th>
            <th className="font-bold p-2 border-b text-left bg-gray-800 text-white">
              NFT
            </th>
            <th className="font-bold p-2 border-b text-left bg-gray-800 text-white">
              Created At
            </th>
            <th className="font-bold p-2 border-b text-left bg-gray-800 text-white">
              Registered At
            </th>
          </tr>
        </thead>
        <tbody>
          {results.map((node) => (
            <tr key={node.Id} className="odd:bg-gray-300">
              <td className="font-bold p-2 border-b border-l text-left ">
                <Link to={`/nodes/${node.id}`}>{node.id}</Link>
              </td>
              <td className="p-2 border-b border-l text-left">{node.nft}</td>
              <td className="p-2 border-b border-l text-right">
                {moment(node.createdAt, moment.ISO_8601).fromNow()}
              </td>
              <td className="p-2 border-b border-l text-right">
                {node.registeredAt === null
                  ? 'Unregistered'
                  : moment(node.registeredAt, moment.ISO_8601).fromNow()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      Page {page.currentPage} of {page.pageCount}
      <br />
      <br />
      {page.hasPrev ? (
        <button
          type="button"
          className="bg-green-500 hover:bg-blue-700 text-white font-bold mr-4 py-2 px-4 rounded"
          onClick={() => goToPage(page.currentPage - 1)}
        >
          Prev Page
        </button>
      ) : (
        'Prev Page'
      )}
      {page.hasNext ? (
        <button
          type="button"
          className="bg-green-500 hover:bg-blue-700 text-white font-bold mr-4 py-2 px-4 rounded"
          onClick={() => goToPage(page.currentPage + 1)}
        >
          Next Page
        </button>
      ) : (
        'Next Page'
      )}
    </div>
  );
};

NodesPage.propTypes = {
  accessToken: PropTypes.string.isRequired,
};

export default NodesPage;
