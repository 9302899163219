import React from 'react';
import authService from '../services/AuthService.js';

const Login = () => {
  const handleLogin = () => {
    return authService.login();
  };

  return (
    <div>
      <h1 className="font-bold text-xl mb-4">Login</h1>
      <p>Click the button to login.</p>
      <div>
        <button
          onClick={handleLogin}
          type="button"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2
                     px-4 rounded"
        >
          Login
        </button>
      </div>
    </div>
  );
};

export default Login;
