import userManager from '../auth/UserManager.js';

class AuthService {
  login() {
    return userManager.signinRedirect();
  }

  logout() {
    return userManager.signoutRedirect();
  }

  async isAuthenticated() {
    const user = await userManager.getUser();
    return Boolean(user !== null) && !user.expired;
  }

  async getProfile() {
    const user = await userManager.getUser();
    return user ? user.profile : null;
  }

  async getUser() {
    const user = await userManager.getUser();
    return user;
  }

  handleCallback() {
    return userManager.signinRedirectCallback();
  }
}

const authService = new AuthService();
export default authService;
