import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';
import {Link, useParams, useNavigate} from 'react-router-dom';

const NodePage = ({accessToken}) => {
  const endpoint = '/nodes';
  const [node, setNode] = useState([]);
  const parameters = useParams();
  const navigate = useNavigate();

  const getNode = (accessToken, id) => {
    return axios.get(`${process.env.REACT_APP_API_URL}${endpoint}/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
      },
    });
  };

  useEffect(() => {
    getNode(accessToken, parameters.nodeId)
      .then((node) => {
        setNode(node.data);
      })
      .catch((error) => console.error('Fetching node failed:', error));
  }, [accessToken, parameters.nodeId, node]);

  const registerNode = (id) => {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}${endpoint}/${id}/register`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
          },
        },
      )
      .then(() => {
        return getNode(accessToken, id).then((node) => {
          setNode(node.data);
        });
      })
      .catch((error) => console.error('Registering node failed:', error));
  };

  const deregisterNode = (id) => {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}${endpoint}/${id}/deregister`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
          },
        },
      )
      .then(() => {
        navigate(`/nodes`, {replace: true});
      })
      .catch((error) => console.error('Deregistering node failed:', error));
  };

  return (
    <div className="w-full">
      <h1 className="font-bold text-3xl mb-4">Node {node.Id}</h1>

      <Link
        to="/nodes"
        type="button"
        className="bg-blue-700 hover:bg-blue-500 text-white font-bold px-2 py-2 my-4 rounded inline-block"
      >
        Back to Nodes
      </Link>

      <table className="table-auto border">
        <thead>
          <tr>
            <th className="font-bold p-2 border-b text-left bg-gray-800 text-white">
              Name
            </th>
            <th className="font-bold p-2 border-b text-left bg-gray-800 text-white">
              Value
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="odd:bg-gray-300">
            <td className="font-bold p-2 border-b border-l text-left ">Id</td>
            <td className="p-2 border-b border-l text-left">{node.id}</td>
          </tr>
          <tr className="odd:bg-gray-300">
            <td className="font-bold p-2 border-b border-l text-left ">NFT</td>
            <td className="p-2 border-b border-l text-left">{node.nft}</td>
          </tr>
          <tr className="odd:bg-gray-300">
            <td className="font-bold p-2 border-b border-l text-left ">
              CreatedAt
            </td>
            <td className="p-2 border-b border-l text-left">
              {moment(node.createdAt, moment.ISO_8601).fromNow()}
            </td>
          </tr>
          <tr className="odd:bg-gray-300">
            <td className="font-bold p-2 border-b border-l text-left ">
              RegisteredAt
            </td>
            <td className="p-2 border-b border-l text-left">
              {node.registeredAt === null
                ? 'Unregistered'
                : moment(node.registeredAt, moment.ISO_8601).fromNow()}
            </td>
          </tr>
        </tbody>
      </table>

      {node.registeredAt === null ? (
        <button
          type="button"
          className="bg-green-500 hover:bg-blue-700 text-white font-bold mr-4 py-2 px-4 rounded"
          onClick={() => registerNode(node.id)}
        >
          Register
        </button>
      ) : (
        <button
          type="button"
          className="bg-green-500 hover:bg-blue-700 text-white font-bold mr-4 py-2 px-4 rounded"
          onClick={() => deregisterNode(node.id)}
        >
          Deregister
        </button>
      )}
    </div>
  );
};

NodePage.propTypes = {
  accessToken: PropTypes.string.isRequired,
};

export default NodePage;
