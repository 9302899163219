import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';

const ImportReleasePage = ({accessToken}) => {
  const endpoint = '/releases';
  const manifestUrlReference = useRef(null);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    if (accessToken) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}${endpoint}`,
          {
            ManifestUrl: manifestUrlReference.current.value,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              Accept: 'application/json',
              'Content-Type': 'application/json;charset=UTF-8',
            },
          },
        )
        .then((response) => {
          console.log('Release created successfully');
          console.log(response.data);
          navigate(`/releases/${response.data.Id}`, {replace: true});
        })
        .catch((error) => console.error('Importing release failed:', error));
    }
  };

  return (
    <div className="w-full">
      <h1 className="font-bold text-3xl mb-4">Import Release</h1>
      <form onSubmit={handleSubmit} className="w-full">
        <div className="mb-6">
          <label
            htmlFor="url"
            className="block mb-2 text-xl font-medium text-gray-900"
          >
            Manifest URL
          </label>
          <input
            ref={manifestUrlReference}
            type="url"
            id="newManifestUrl"
            name="manifest_url"
            placeholder="https://example.com/manifest.json"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            required={true}
          />
        </div>
        <button
          type="submit"
          className="text-white bg-blue-500 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 text-lg font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
        >
          Import Release
        </button>
      </form>
    </div>
  );
};

ImportReleasePage.propTypes = {
  accessToken: PropTypes.string.isRequired,
};

export default ImportReleasePage;
