import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';
import {Link} from 'react-router-dom';

const PoolsPage = ({accessToken}) => {
  const endpoint = '/pools';
  const [pools, setPools] = useState([]);

  const getPools = (accessToken) => {
    return axios.get(`${process.env.REACT_APP_API_URL}${endpoint}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    });
  };

  useEffect(() => {
    getPools(accessToken)
      .then((releases) => {
        setPools(releases.data);
      })
      .catch((error) => console.error('Fetching releases failed:', error));
  }, [accessToken]);

  return (
    <div className="w-full">
      <h1 className="font-bold text-3xl mb-4">Pools</h1>
      <div className="w-full text-right">
        <Link
          to="/pools/new"
          type="button"
          className="bg-blue-700 hover:bg-blue-500 text-white font-bold px-2 py-2 my-4 rounded inline-block"
        >
          New Pool
        </Link>
      </div>

      <table className="table-auto border w-full clear-both">
        <thead>
          <tr>
            <th className="font-bold p-2 border-b text-left bg-gray-800 text-white">
              Pool Id
            </th>
            <th className="font-bold p-2 border-b text-left bg-gray-800 text-white">
              Name
            </th>
            <th className="font-bold p-2 border-b text-left bg-gray-800 text-white">
              Description
            </th>
            <th className="font-bold p-2 border-b text-left bg-gray-800 text-white">
              Access Key
            </th>
            <th className="font-bold p-2 border-b text-left bg-gray-800 text-white">
              Created At
            </th>
          </tr>
        </thead>
        <tbody>
          {pools.map((pool) => (
            <tr key={pool.Id} className="odd:bg-gray-300">
              <td className="font-bold p-2 border-b border-l text-left ">
                <Link to={`/pools/${pool.Id}`}>{pool.Id}</Link>
              </td>
              <td className="p-2 border-b border-l text-left">{pool.Name}</td>
              <td className="p-2 border-b border-l text-left">
                {pool.Description}
              </td>
              <td className="p-2 border-b border-l text-left">
                {pool.AccessKey}
              </td>
              <td className="p-2 border-b border-l text-right">
                {moment(pool.CreatedAt, moment.ISO_8601).fromNow()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

PoolsPage.propTypes = {
  accessToken: PropTypes.string.isRequired,
};

export default PoolsPage;
