import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

const Navigation = ({isLoggedIn}) => {
  return (
    <div className="w-1/4 bg-gray-800 text-white p-4">
      <h1 className="font-bold text-lg">Navigation</h1>
      {isLoggedIn ? (
        <ul>
          <li>
            <Link to="/" className="text-white hover:text-gray-300">
              Home
            </Link>
          </li>
          <li>
            <Link to="/profile" className="text-white hover:text-gray-300">
              Profile
            </Link>
          </li>

          <li>
            <Link to="/pools" className="text-white hover:text-gray-300">
              Node Pool Management
            </Link>
          </li>

          <li>
            <Link to="/nodes" className="text-white hover:text-gray-300">
              File Node Management
            </Link>
          </li>

          <li>
            <Link to="/delegations" className="text-white hover:text-gray-300">
              Delegation Management
            </Link>
          </li>

          <li>
            <Link to="/releases" className="text-white hover:text-gray-300">
              Release Management
            </Link>
          </li>

          <li>
            <Link to="/logout" className="text-white hover:text-gray-300">
              Logout
            </Link>
          </li>
        </ul>
      ) : (
        <ul>
          <li>
            <Link to="/" className="text-white hover:text-gray-300">
              Home
            </Link>
          </li>
          <li>
            <Link to="/login" className="text-white hover:text-gray-300">
              Login
            </Link>
          </li>
        </ul>
      )}
    </div>
  );
};

Navigation.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};

export default Navigation;
