import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';
import {Link, useParams} from 'react-router-dom';

const DelegationPage = ({accessToken}) => {
  const endpoint = '/delegations';
  const [delegation, setDelegation] = useState([]);
  const parameters = useParams();

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}${endpoint}/${parameters.delegationId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
          },
        },
      )
      .then((delegation) => {
        console.log(delegation.data);
        console.log('Delegation fetched successfully');
        setDelegation(delegation.data);
      })
      .catch((error) => console.error('Fetching delegation failed:', error));
  }, [accessToken, parameters.delegationId]);

  return (
    <div className="w-full">
      <h1 className="font-bold text-3xl mb-4">Delegation</h1>
      <table className="table-auto border">
        <thead>
          <tr>
            <th className="font-bold p-2 border-b text-left bg-gray-800 text-white">
              Name
            </th>
            <th className="font-bold p-2 border-b text-left bg-gray-800 text-white">
              Value
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="odd:bg-gray-300">
            <td className="font-bold p-2 border-b border-l text-left ">Id</td>
            <td className="p-2 border-b border-l text-left">{delegation.Id}</td>
          </tr>
          <tr className="odd:bg-gray-300">
            <td className="font-bold p-2 border-b border-l text-left ">
              Pool Name
            </td>
            <td className="p-2 border-b border-l text-left">
              {delegation.PoolName}
            </td>
          </tr>
          <tr className="odd:bg-gray-300">
            <td className="font-bold p-2 border-b border-l text-left ">
              Node Name
            </td>
            <td className="p-2 border-b border-l text-left">
              {delegation.NodeName}
            </td>
          </tr>
          <tr className="odd:bg-gray-300">
            <td className="font-bold p-2 border-b border-l text-left ">
              CreatedAt
            </td>
            <td className="p-2 border-b border-l text-left">
              {moment(delegation.CreatedAt, moment.ISO_8601).fromNow()}
            </td>
          </tr>
        </tbody>
      </table>
      <Link to="/delegations">Back to Delegations</Link>
    </div>
  );
};

DelegationPage.propTypes = {
  accessToken: PropTypes.string.isRequired,
};

export default DelegationPage;
